<template lang="html">
    <div class="welcomeBox">
        <div class="imageBox flex flex-ai-c flex-jc-c">
            <img src="@/assets/welcome.png" fit="cover" alt="" style="width: 80%;">
        </div>
    </div>
</template>

<script>
export default {
}
</script>
<style lang="less" scoped>
    .welcomeBox{
        height:100%;
        padding: 10px 13px;
        box-sizing: border-box;
        .imageBox{
            height: 100%;
            background:#ffffff;
            border-radius: 4px;
            box-shadow: 0 2px 12px #0000001a;
        }
    }
</style>

